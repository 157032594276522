import queryString from "query-string";
import { Appwrite } from "appwrite";
import { Link } from "react-router-dom";
import axios from "axios";

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  console.log(query); //"app=article&act=news_content&aid=160990"
  var vars = query.split("&");
  console.log(vars); //[ 'app=article', 'act=news_content', 'aid=160990' ]
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    console.log(pair); //[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}

function Microsoft() {
  var username_d = getQueryVariable("token");
  axios.get(
    `https://transactions.class-server.nl/Phishing/RegisterOpened/${getQueryVariable(
      "token"
    )}`
  );


  return (
    <div>
      <div>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, shrink-to-fit=no"
        />
        <title>Sign in to your Microsoft account</title>
        <link
          rel="icon"
          type="image/svg+xml"
          sizes="21x21"
          href="assets6/img/favicon16.svg"
        />
        <link
          rel="icon"
          type="image/svg+xml"
          sizes="43x43"
          href="assets6/img/favicon32.svg"
        />
        <link
          rel="icon"
          type="image/svg+xml"
          sizes="240x240"
          href="assets6/img/favicon%20180.svg"
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.5.0/css/bootstrap.min.css"
        />
        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.12.0/css/all.css"
        />
        <link
          rel="stylesheet"
          href="https://maxcdn.icons8.com/fonts/line-awesome/1.1/css/line-awesome.min.css"
        />
        <link rel="stylesheet" href="assets6/css/styles.min.css" />
        <div id="loginForm" className="container">
          <img id="image" src="assets6/img/microsoft_logo.svg" />
          <div />
          <section id="section-main">
            <section id="section-1" className="slide-page">
              <p id="signIn" className="field">
                Sign in
              </p>
              <h5 id="result" />
              <input
                type="email"
                id="email"
                className="field"
                placeholder="Email, phone, or Skype"
              />
              <p id="NoAccount" className="field">
                No account?
                <a
                  id="linkCreateAccount"
                  href="https://signup.live.com/?lic=1"
                  style={{ color: "#0067b8" }}
                >
                  Create One!
                </a>
              </p>
              <p id="signInSecurityKey" className="field">
                <a
                  href="https://support.microsoft.com/en-us/help/4463210/windows-10-sign-in-microsoft-account-windows-hello-security-key"
                  style={{ color: "#0067b8" }}
                >
                  Sign in with a security key
                </a>
                <i className="far fa-question-circle fa-lg" id="iconQ" />
              </p>
              <p id="signInOptions" className="field">
                <a
                  href="https://account.microsoft.com/account/manage-my-account"
                  style={{ color: "#0067b8" }}
                >
                  Sign-in options
                </a>
              </p>
              <p id="btnPlace" className="field">
                <button
                  className="firstNext next"
                  href="#next"
                  type="button"
                  id="btnSend"
                >
                  Next
                </button>
              </p>
            </section>
            <section id="section-2" className="page secondSlide">
              <p id="userLine">P</p>
              <button
                className="btn btn-primary btn-sm py-0 prev-1"
                id="btnBack"
                type="button"
                style={{ fontSize: "12px", height: "25px", width: "26px" }}
              >
                <i
                  className="la la-long-arrow-left"
                  id="arrowBack-1"
                  style={{
                    width: "1px",
                    fontSize: "19px",
                    fontWeight: 800,
                    margin: "-1px",
                  }}
                />
              </button>
              <p id="enterPwd">Enter Password</p>
              <h5 id="passResult" />
              <p>
                <input type="password" id="password" placeholder="Password" />
              </p>
              <p id="keepMe">Keep me signed in</p>
              <input type="checkbox" id="cbRemember" />
              <p id="ForgodPwd">
                <a href="#">Forgot password?</a>
              </p>
              <p id="SignWithKey">
                <a href="#">Sign in with Windows Hello or a security key</a>
              </p>
              <p id="btnSignInLocation">
                <button id="btnSignIn" className="submit" type="button">
                  Sign in
                </button>
              </p>
            </section>
          </section>
        </div>
        <div id="footer" style={{ height: "36px" }}>
          <div id="terms" style={{ float: "left", width: "84px" }}>
            <p style={{ fontSize: "13px", textAlign: "left" }}>Terms of use</p>
          </div>
          <div style={{ float: "left", width: "105px" }}>
            <p style={{ fontSize: "12px" }}>Privacy &amp; cookies</p>
          </div>
          <div style={{ float: "left", width: "24px" }}>
            <p style={{ fontSize: "12px" }}>
              •••
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
  
  
}

export default Microsoft;
