import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import logo from "./logoLong.png";
import logoHeader from "./hengel.png";
import backgroundLogo from "./websitev2.png";

export default function AlertDialog() {
  const [open, setOpen] = React.useState(true);

  const handleTips = () => {
    window.location.assign("https://justspark.com/phishing-as-a-service-bewuster-omgaan-met-digitale-bedreigingen/");
  };

  const handleMeerInfo = () => {
    window.location.assign("https://justspark.com/phishing-as-a-service-bewuster-omgaan-met-digitale-bedreigingen/");
  };
  function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    console.log(query); //"app=article&act=news_content&aid=160990"
    var vars = query.split("&");
    console.log(vars); //[ 'app=article', 'act=news_content', 'aid=160990' ]
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      console.log(pair); //[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return false;
  }
  function ReportBack(){
            var username_d = getQueryVariable("token");
            axios.get(
              `https://transactions.class-server.nl/Phishing/RegisterLoggedIn/${getQueryVariable("token")}`
            );
  }

  return (
    <div>
      {ReportBack()}
      <div>
        <img src={backgroundLogo} class="Wallpaper" />
      </div>
      <Dialog
        open={open}
        onClose={handleMeerInfo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Phishing-as-a-Service"}
        </DialogTitle>
        <DialogContent>
          <div>
            <img src={logoHeader} width="50%" class="centerHeaderImage" />
          </div>
          <DialogContentText id="alert-dialog-description" class="textPhishing">
            Oei! U bent in de val gelopen en hebt niet doorgehad dat het hier
            een zogenaamde phishing-aanval betrof. Door te klikken op de link in
            de mail en het invullen van uw wachtwoord heeft u data verstrekt aan
            een mogelijk criminele partij… <br /> <br /> Gelukkig was dit een
            test, opgezet door Justspark en zijn uw gegevens niet
            opgeslagen of in verkeerde handen terecht gekomen. U hoeft zich dus
            geen zorgen te maken!
            <br />
            <br /> Wel willen wij u erop attenderen dat bij een echte
            phishing-aanval niet alleen uw gebruikersnaam en wachtwoord, maar
            ook bedrijf kritische informatie zoals uw IP adres, Wi-Fi netwerk,
            locatie, en Windows of Mac versie worden verzonden. Met deze
            gegevens kunnen kwaadwillenden veel schade aanrichten.
            <br /> <br /> Klik nooit op een link in een bericht waarvan u de
            afzender niet vertrouwd! Neem bij twijfel altijd contact op met
            Justspark via mail (
            <a href="mailto:support@justspark.com">support@justspark.com</a>) of
            telefoon. <br/>
          </DialogContentText>
          <div>
            <img src={logo} class="center" />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleTips} color="primary">
            Verifiëren via de officiële website van Justspark
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
