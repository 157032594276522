import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Outlook from './Outlook';
import OutlookEnglish from './OutlookEnglish';
import Failed from './Failed';
import AlertDialog from './AlertDialog';
import AlertDialogEN from './AlertDialogEN';
import FacebookTrap from './FacebookTrap';
import GoogleTrap from './GoogleTrap';
import LinkedIn from './LinkedIn';
import Microsoft from './Microsoft';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/1" render={(props) => <Outlook {...props} />} />
        <Route
          exact
          path="/2"
          render={(props) => <FacebookTrap {...props} />}
        />
        <Route exact path="/3" render={(props) => <GoogleTrap {...props} />} />
        <Route
          exact
          path="/4"
          render={(props) => <OutlookEnglish {...props} />}
        />
        <Route
          exact
          path="/5"
          render={(props) => <LinkedIn {...props} />}
        />
        <Route
          exact
          path="/6"
          render={(props) => <Microsoft {...props} />}
        />
        <Route
          exact
          path="/failed"
          render={(props) => <AlertDialog {...props} />}
        />
        <Route
          exact
          path="/failedEN"
          render={(props) => <AlertDialogEN {...props} />}
        />
      </Switch>
    </Router>
  );
}

export default App;
