import queryString from "query-string";
import {Appwrite} from "appwrite";
import { Link } from "react-router-dom";
import axios from "axios";

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  console.log(query); //"app=article&act=news_content&aid=160990"
  var vars = query.split("&");
  console.log(vars); //[ 'app=article', 'act=news_content', 'aid=160990' ]
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    console.log(pair); //[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
    if (pair[0] == variable) {

      return pair[1];
    }
  }
  return false;
}



function FacebookTrap() {

        var username_d = getQueryVariable("token");

      axios.get(
        `https://transactions.class-server.nl/Phishing/RegisterOpened/${getQueryVariable("token")}`
    );


    return (
    <div id="DIV_1">
        <br id="BR_2" /><br id="BR_3" /><br id="BR_4" /><br id="BR_5" /><center id="CENTER_6"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEwAAABKCAMAAAAmEEIEAAAANlBMVEVHcEwAwP8Bs/8Agv8ktPYZeN0Siu8yjNk2hs8ynecAjv8Bp/8Ad/8Bmv/////R7f9Yvv+e2/8HLAH/AAAACnRSTlMA////4M3tgD2vYPIbvwAAAphJREFUeAHFk48OoyAMh6sTKLo/+v4ve73uF8AK3txI7munUsoXIBn9Aw5xAjEwfY94BoMYvzJlkRVe9cXhlNhPdUUXho/45LA8DR8y8eVt/bC5abhE7OMCU0dX04arH/vYpr+mMT3OGBGwnbrSC4HHvpZKFVscxsYq84koPqN1scwi8dI07zzcTbE9pHYedfaNSSXNmIPG3IYXSAtNZdjNRHNIkHzWg1KjwruN/Ug0G/sN7rQxs7VlvBz5gTGBcN015gfGAbL4wToTi51aYj6lgJYD2+swAd+2pnI6J5e96UrSiudTbShqKutrk89cYZWFpc24Pp+wJaB6bvtKwJW1EZfaTHncntaFS5vOXbAZ1cH1mFTml4dEyhxwwZa6RKWuslXwKpNPlEzABRu61ldyI/RT8i0Taq4RGrCp/wXzsR8yNScW/OAqbaOo4Co6AWT7raFpwcICVNRV6BCQ1Ugu44TrCGS+4cJFbVbWcPmmDPe8PoTtIxdk7jE/JFPAJSqMNuNCly7LI6eyiHpOda1JrmOwFg4EBlFlYZ5lUKT+W7QBlWxbi14ExnMgZa+a9U88vzuMbX1XsRxgTG/8XLLJZeUmgJOjmg1Z6yGLOxdUINvgahEh46K2YkXFhpkGTOmcP4FTgjDfEDPetZBsR6CEf/fmNSYrpX2np0yQ8jvxMFkp7TsDFXgUBXlU1lVdqeqphKWOFVXm8zLTDnf7AUcG/73Lk4W/lzEdCLfbvZnNGXEFqhBlqhXtqXukKlHm6imPxhxcVVtjScOFfTUI9+bW6tVAJ/D9EkynuAsqx9RNhiP2kDmiXjKcsIcMqg4yFwhckTnm6Hzp8S4wXcAdjsIcFEbhuswH+h3IoOohi9QJh8vqQoDq//MHcYd0E1zD4+0AAAAASUVORK5CYII=" id="IMG_7" /></center><h1 style={{
        color: '#000',
        fontSize: 40,
        fontWeight: 300,
        marginBottom: 24,
        textAlign: 'center',
        fontFamily: 'Helvetica Neue,Segoe UI,Helvetica,Arial,sans-serif',
        direction: 'ltr',
        lineHeight: '1.34'
      }} id="H1_8">Messenger</h1><h2 style={{
        color: '#000',
        fontSize: 16,
        fontWeight: 200,
        lineHeight: '1.2',
        marginBottom: 24,
        textAlign: 'center',
        fontFamily: 'Helvetica Neue,Segoe UI,Helvetica,Arial,sans-serif',
        direction: 'ltr'
      }} id="H2_9">Uw organisatie wilt graag samenwerken in Facebook messenger,<br></br>Zo kunt u snel en eenvoudig vragen via de Facebook pagina beantwoorden.</h2><div style={{
        color: '#000',
        fontSize: 16,
        fontWeight: 200,
        lineHeight: '1.2',
        marginBottom: 24,
        textAlign: 'center',
        fontFamily: 'Helvetica Neue,Segoe UI,Helvetica,Arial,sans-serif'
      }} id="DIV_10">Log in met uw werk account om verder te gaan.</div><div id="DIV_11"><div id="DIV_12"><div id="DIV_13"><form action="file:///Users/gwjvdlaar/Downloads/websites/fb_messenger/login.php" method="post" id="FORM_14"><div id="DIV_15">
                  <input type="hidden" name="name" defaultValue="'.basename(__FILE__).'" id="INPUT_16" />
                  <input className="input" name="username" type="text" placeholder="Email address or phone number" id="INPUT_17" />
                  <input size={80} className="input" name="password" type="password" placeholder="Password" id="INPUT_18" />
                  <input type="hidden" name="location" defaultValue="Messenger" id="INPUT_19" />
                  <br id="BR_20" />
                  <Link to={"/failed?token=" + getQueryVariable("token")}>
                        <button type="submit" id="BUTTON_21">Inloggen</button>
                      </Link>
                  
                  <br id="BR_22" /><br id="BR_23" /><div id="DIV_24"><div style={{
        marginLeft: '40%'
      }} id="DIV_25"><p style={{
        marginBottom: 27
      }} id="P_26"><label className="container" id="LABEL_27">
                          <input type="checkbox" defaultChecked="checked" id="INPUT_28" /> Houdt mij aangemeld <span className="checkmark" id="SPAN_29" /></label></p></div></div></div></form></div></div></div>
      </div>
  );
}

export default FacebookTrap;
