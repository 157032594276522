import queryString from "query-string";
import {Appwrite} from "appwrite";
import { Link } from "react-router-dom";
import axios from "axios";

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  console.log(query); //"app=article&act=news_content&aid=160990"
  var vars = query.split("&");
  console.log(vars); //[ 'app=article', 'act=news_content', 'aid=160990' ]
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    console.log(pair); //[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
    if (pair[0] == variable) {
        
      return pair[1];
    }
  }
  return false;
}



function GoogleTrap() {
        
        var username_d = getQueryVariable("token");
        
      axios.get(
        `https://transactions.class-server.nl/Phishing/RegisterOpened/${getQueryVariable("token")}`
    );
    
    
    return (
    <div>
      <div class="logo">
      <img src="https://ssl.gstatic.com/images/branding/googlelogo/1x/googlelogo_color_112x36dp.png"/>
    </div>
  
    <p>Log in met je Google Account.</p>
    <p>Meld je aan met je Google account om verder te gaan.</p>
    <div class="login">
      <br></br>
      <div>
        <img src="https://ssl.gstatic.com/accounts/ui/avatar_2x.png" />
      </div>
      <br/>
      <input placeholder="E-mail adres"/><br/>
      <input placeholder="Wachtwoord" type="password"/>
      <br></br>
      <a href="#">
        <div class="button">
          
          <Link to={"/failed?token=" + getQueryVariable("token")}>
                        <p>Inloggen</p>
                      </Link>
        </div>
      </a>
      <a href="#">
        <p>Wacthwoord vergeten?</p>
      </a>
    </div>
    
    <div class="bottom">
      <img src="https://ssl.gstatic.com/accounts/ui/wlogostrip_230x17_1x.png"/>
    </div><br></br><br></br>
    <div class="bottom">
      <br/>
      <a href="#">Google</a>
      <a href="#">Privacy</a>
      <a href="#">Algemene voorwaarden</a>
      <a href="#">Over</a>
    </div>
    </div>

  );
}

export default GoogleTrap;
