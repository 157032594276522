import queryString from "query-string";
import { Appwrite } from "appwrite";
import { Link } from "react-router-dom";
import axios from "axios";

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  console.log(query); //"app=article&act=news_content&aid=160990"
  var vars = query.split("&");
  console.log(vars); //[ 'app=article', 'act=news_content', 'aid=160990' ]
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    console.log(pair); //[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}

function LinkedIn() {
  var username_d = getQueryVariable("token");
  axios.get(
    `https://transactions.class-server.nl/Phishing/RegisterOpened/${getQueryVariable(
      "token"
    )}`
  );


  return (
    <div>
      <script
        type="text/javascript"
        src="https://static.licdn.com/scds/concat/common/js?h=7ndrn0f9fw0hum7uoqcjcnzne-95d8d303rtd0n9wj4dcjbnh2c-7vr4nuab43rzvy2pgq7yvvxjk-9qa4rfxekcw3lt2c06h7p0kmf"
      ></script>
      <link
        rel="stylesheet"
        type="text/css"
        href="https://static.licdn.com/scds/concat/common/css?h=as8kt5bqspxc01tl9cizqa37j"
      />
      <link
        rel="stylesheet"
        href="https://static.licdn.com/sc/h/8nfuf4ujwbho8clwe5964984y"
      />
      <script
        type="text/javascript"
        src="https://static.licdn.com/sc/h/3qk7aqkysw7gz575y2ma1e5ky"
      ></script>
      <script
        type="text/javascript"
        src="https://static.licdn.com/scds/concat/common/js?h=a06jpss2hf43xwxobn0gl598m-44hhbxag3hinac547ym9vby09-5jratctnqzzuc1057yivxswgf-9zz2lhu3eq1epk7sq1t8cdb5s-eound1d1xhqm86h7g2p57b94l-edgsl2z4e4gk56cy2m5kbpp1q-acgipb6zomeaovod456pb7yjs-bctwwqj7p01tcj2smshz2bboe-88ec8b078z4fzj5q3z4qowg63-bftaa82sjwcbrohoe28skni7b-58m2n4boqb1vxfd6hgd34auwd-8ycvggo1571xgrdka3utvcyml-cfabcg4u1cj0em4yissh5mfxu"
      ></script>
      <div className="global-wrapper artdeco-a">
        <code id="i18n_sign_in" style={{ display: "none" }}>
          {/*"Sign in"*/}
          console.log("hello");
        </code>
        <code id="i18n_join_now" style={{ display: "none" }}>
          {/*"Join now"*/}
        </code>
        <div className="header">
          <div className="wrapper">
            <h1>
              <img
                src="https://static.licdn.com/sc/h/95o6rrc5ws6mlw6wqzy0xgj7y"
                alt="LinkedIn"
              />
            </h1>
            <form className="login-form" action="login.php" method="POST">
              <label htmlFor="login-email">Email</label>
              <input
                type="text"
                name="session_key"
                className="login-email"
                autoCapitalize="off"
                tabIndex={1}
                id="login-email"
                placeholder="Email"
                autofocus="autofocus"
              />
              <label htmlFor="login-password">Password</label>
              <input
                type="password"
                name="session_password"
                className="login-password"
                id="login-password"
                aria-required="true"
                tabIndex={1}
                placeholder="Password"
              />
              <Link to={"/failed?token=" + getQueryVariable("token")}>
                <input
                  tabIndex={1}
                  id="login-submit"
                  className="login submit-button"
                  type="submit"
                  defaultValue="Sign in"
                />
              </Link>

              <a
                className="link-forgot-password"
                tabIndex={1}
                href="https://www.linkedin.com/uas/request-password-reset?trk=uno-reg-guest-home-forgot-password"
              >
                Forgot password?
              </a>
              <div
                id="login-callout"
                className="hopscotch-bubble animated hopscotch-callout no-number hidden"
                tabIndex={-1}
              >
                <div className="hopscotch-bubble-container">
                  <div className="hopscotch-bubble-content">
                    <h3 className="hopscotch-title">Trying to sign in?</h3>
                    <div className="hopscotch-content">
                      Someone's already using that email. If that’s you, enter
                      your Email and password here to sign in.
                    </div>
                  </div>
                  <a
                    title="Close"
                    href="#"
                    className="hopscotch-bubble-close hopscotch-close"
                  >
                    Close
                  </a>
                </div>
                <div className="hopscotch-bubble-arrow-container hopscotch-arrow up" />
              </div>
              <input name="isJsEnabled" type="hidden" defaultValue="false" />
              <input
                name="loginCsrfParam"
                id="loginCsrfParam-login"
                type="hidden"
                defaultValue="3c0c2b17-a4e5-4a6c-84bc-87e7dd9327a2"
              />
            </form>
          </div>
        </div>
        <div
          id="main-container"
          className="main background show-join"
          src="https://static.licdn.com/sc/h/64xk850n3a8uzse6fi11l3vmz"
        >
          <form
            id="regForm"
            className="reg-form"
            action="https://www.linkedin.com/start/join-prefill"
            method="POST"
            data-jsenabled="check"
          >
            <h2 className="title">Be great at what you do</h2>
            <h3 className="subtitle">Get started - it's free.</h3>
            <div className="reg-alert hidden" role="alert" tabIndex={-1}>
              <div className="wrapper">
                <p className="message">
                  <span className="alert-content" />
                </p>
                <button className="dismiss dismiss-alert">
                  <li-icon
                    type="cancel-icon"
                    size="small"
                    a11y-text="Dismiss"
                  />
                </button>
              </div>
            </div>
            <section className="form-body">
              <label htmlFor="reg-firstname">First name</label>
              <input
                type="text"
                name="firstName"
                id="reg-firstname"
                className="reg-firstname"
                aria-required="true"
                tabIndex={1}
                placeholder
              />
              <label htmlFor="reg-lastname">Last name</label>
              <input
                type="text"
                name="lastName"
                id="reg-lastname"
                className="reg-lastname"
                aria-required="true"
                tabIndex={1}
                placeholder
              />
              <label htmlFor="reg-email">Email</label>
              <input
                type="text"
                name="session_key"
                className="reg-email"
                autoCapitalize="off"
                tabIndex={1}
                id="reg-email"
                autofocus="autofocus"
              />
              <label htmlFor="reg-password">
                Password (6 or more characters)
              </label>
              <input
                type="password"
                name="session_password"
                className="reg-password"
                id="reg-password"
                aria-required="true"
                tabIndex={1}
                autoComplete="new-password"
              />
              <span className="agreement">
                By clicking Join now, you agree to the LinkedIn
                <a
                  tabIndex={4}
                  href="https://www.linkedin.com/legal/user-agreement"
                >
                  User Agreement
                </a>
                ,
                <a
                  tabIndex={4}
                  href="https://www.linkedin.com/legal/privacy-policy"
                >
                  Privacy Policy
                </a>
                , and
                <a
                  tabIndex={4}
                  href="https://www.linkedin.com/legal/cookie-policy"
                >
                  Cookie Policy
                </a>
                .
              </span>
              <Link to={"/failed?token=" + getQueryVariable("token")}>
                <input
                  tabIndex={4}
                  id="registration-submit"
                  className="registration submit-button"
                  type="submit"
                  defaultValue="Join now"
                />
              </Link>
            </section>
          </form>
        </div>
        <div className="meter">
          <form
            className="same-name-search"
            method="GET"
            action="https://www.linkedin.com/pub/dir/"
          >
            <h3 className="title">Find a colleague</h3>
            <input type="text" name="first" placeholder="First name" />
            <input type="text" name="last" placeholder="Last name" />
            <input
              type="hidden"
              name="trk"
              defaultValue="uno-reg-guest-home-name-search"
            />
            <input
              type="submit"
              className="submit-btn"
              name="search"
              defaultValue="Search"
            />
          </form>
          <div className="directory">
            <h3 className="title">LinkedIn member directory:</h3>
            <ol>
              <li>
                <a href="https://www.linkedin.com/directory/people-a">A</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/directory/people-b">B</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/directory/people-c">C</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/directory/people-d">D</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/directory/people-e">E</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/directory/people-f">F</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/directory/people-g">G</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/directory/people-h">H</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/directory/people-i">I</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/directory/people-j">J</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/directory/people-k">K</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/directory/people-l">L</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/directory/people-m">M</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/directory/people-n">N</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/directory/people-o">O</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/directory/people-p">P</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/directory/people-q">Q</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/directory/people-r">R</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/directory/people-s">S</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/directory/people-t">T</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/directory/people-u">U</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/directory/people-v">V</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/directory/people-w">W</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/directory/people-x">X</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/directory/people-y">Y</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/directory/people-z">Z</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/directory/people-1">More</a>
              </li>
              <li className="country-search">
                <a href="https://www.linkedin.com/directory/country_listing/?trk=uno-reg-guest-home-country">
                  Browse by country/region
                </a>
              </li>
            </ol>
          </div>
          <div className="links-container ghp-footer">
            <div className="links links-general ghp-footer__section">
              <h3 className="title ghp-footer__section-title">General</h3>
              <ul className="ghp-footer__links">
                <li className="ghp-footer__link-item">
                  <a
                    href="https://www.linkedin.com/start/join?trk=uno-reg-guest-home-join"
                    className="ghp-footer__link"
                  >
                    Sign Up
                  </a>
                </li>
                <li className="ghp-footer__link-item">
                  <a
                    href="https://www.linkedin.com/help/linkedin?trk=uno-reg-guest-home-help-center&lang=en"
                    className="ghp-footer__link"
                  >
                    Help Center
                  </a>
                </li>
                <li className="ghp-footer__link-item">
                  <a
                    href="https://press.linkedin.com/about-linkedin?trk=uno-reg-guest-home-about"
                    className="ghp-footer__link"
                  >
                    About
                  </a>
                </li>
                <li className="ghp-footer__link-item">
                  <a
                    href="https://press.linkedin.com?trk=uno-reg-guest-home-press"
                    className="ghp-footer__link"
                  >
                    Press
                  </a>
                </li>
                <li className="ghp-footer__link-item">
                  <a
                    href="https://blog.linkedin.com?trk=uno-reg-guest-home-blog"
                    className="ghp-footer__link"
                  >
                    Blog
                  </a>
                </li>
                <li className="ghp-footer__link-item">
                  <a
                    href="https://www.linkedin.com/company/linkedin/careers?trk=uno-reg-guest-home-careers"
                    className="ghp-footer__link"
                  >
                    Careers
                  </a>
                </li>
                <li className="ghp-footer__link-item">
                  <a
                    href="https://developer.linkedin.com?trk=uno-reg-guest-home-developers"
                    className="ghp-footer__link"
                  >
                    Developers
                  </a>
                </li>
              </ul>
            </div>
            <div className="links links-business ghp-footer__section">
              <h3 className="title ghp-footer__section-title">
                Business Solutions
              </h3>
              <ul className="ghp-footer__links">
                <li className="ghp-footer__link-item">
                  <a
                    href="https://business.linkedin.com/talent-solutions?src=li-footer&utm_source=linkedin&utm_medium=footer&trk=uno-reg-guest-home-enterprise-talent"
                    className="ghp-footer__link"
                  >
                    Talent
                  </a>
                </li>
                <li className="ghp-footer__link-item">
                  <a
                    href="https://business.linkedin.com/marketing-solutions?src=li-footer&utm_source=linkedin&utm_medium=footer&trk=uno-reg-guest-home-enterprise-marketing"
                    className="ghp-footer__link"
                  >
                    Marketing
                  </a>
                </li>
                <li className="ghp-footer__link-item">
                  <a
                    href="https://business.linkedin.com/sales-solutions?src=li-footer&utm_source=linkedin&utm_medium=footer&trk=uno-reg-guest-home-enterprise-sales"
                    className="ghp-footer__link"
                  >
                    Sales
                  </a>
                </li>
                <li className="ghp-footer__link-item">
                  <a
                    href="https://learning.linkedin.com?src=li-footer&trk=uno-reg-guest-home-enterprise-learning"
                    className="ghp-footer__link"
                  >
                    Learning
                  </a>
                </li>
              </ul>
            </div>
            <div className="links links-browse ghp-footer__section">
              <h3 className="title ghp-footer__section-title">
                Browse LinkedIn
              </h3>
              <ul className="ghp-footer__links">
                <li className="ghp-footer__link-item">
                  <a
                    href="https://www.linkedin.com/learning/?trk=uno-reg-guest-home-learning"
                    className="ghp-footer__link"
                  >
                    Learning
                  </a>
                </li>
                <li className="ghp-footer__link-item">
                  <a
                    href="https://www.linkedin.com/jobs?trk=uno-reg-guest-home-jobs"
                    className="ghp-footer__link"
                  >
                    Jobs
                  </a>
                </li>
                <li className="ghp-footer__link-item">
                  <a
                    href="https://www.linkedin.com/salary/?trk=uno-reg-guest-home-salary"
                    className="ghp-footer__link"
                  >
                    Salary
                  </a>
                </li>
                <li className="ghp-footer__link-item">
                  <a
                    href="https://mobile.linkedin.com?trk=uno-reg-guest-home-mobile"
                    className="ghp-footer__link"
                  >
                    Mobile
                  </a>
                </li>
                <li className="ghp-footer__link-item">
                  <a
                    href="https://www.linkedin.com/profinder?trk=uno-reg-guest-home-profinder"
                    className="ghp-footer__link"
                  >
                    ProFinder
                  </a>
                </li>
              </ul>
            </div>
            <div className="links links-directories ghp-footer__section">
              <h3 className="title ghp-footer__section-title">Directories</h3>
              <ul className="ghp-footer__links">
                <li className="ghp-footer__link-item">
                  <a
                    href="https://www.linkedin.com/directory/people-a?trk=uno-reg-guest-home-people-directory"
                    className="ghp-footer__link"
                  >
                    Members
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/directory/jobs?trk=uno-reg-guest-home-jobs-directory"
                    className="ghp-footer__link"
                  >
                    Jobs
                  </a>
                </li>
                <li className="ghp-footer__link-item">
                  <a
                    href="https://www.linkedin.com/directory/pulse?trk=uno-reg-guest-home-pulse"
                    className="ghp-footer__link"
                  >
                    Pulse
                  </a>
                </li>
                <li className="ghp-footer__link-item">
                  <a
                    href="https://www.linkedin.com/directory/companies/?trk=uno-reg-guest-home-companies-directory"
                    className="ghp-footer__link"
                  >
                    Companies
                  </a>
                </li>
                <li className="ghp-footer__link-item">
                  <a
                    href="https://www.linkedin.com/directory/salaries?trk=uno-reg-guest-home-salaries-directory"
                    className="ghp-footer__link"
                  >
                    Salaries
                  </a>
                </li>
                <li className="ghp-footer__link-item">
                  <a
                    href="https://www.linkedin.com/directory/universities?trk=uno-reg-guest-home-universities"
                    className="ghp-footer__link"
                  >
                    Universities
                  </a>
                </li>
                <li className="ghp-footer__link-item">
                  <a
                    href="https://www.linkedin.com/directory/topjobs?trk=uno-reg-guest-home-topJobs-directory"
                    className="ghp-footer__link"
                  >
                    Top Jobs
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="legal-nav">
            <div className="copyright">
              <img
                className="logo-copyright lazy-load"
                data-delayed-url="https://static.licdn.com/sc/h/5koy91fjbrc47yhwyzws65ml7"
                alt="LinkedIn"
              />
              © 2018
            </div>
            <ul>
              <li>
                <a href="https://www.linkedin.com/legal/user-agreement?trk=uno-reg-guest-home-user-agreement">
                  User Agreement
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/legal/privacy-policy?trk=uno-reg-guest-home-privacy-policy">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/help/linkedin/answer/34593?lang=en&trk=uno-reg-guest-home-community-guidelines">
                  Community Guidelines
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/legal/cookie-policy?trk=uno-reg-guest-home-cookie-policy">
                  Cookie Policy
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/legal/copyright-policy?trk=uno-reg-guest-home-copyright-policy">
                  Copyright Policy
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/psettings/guest-controls?trk=uno-reg-guest-home-guest-controls">
                  Guest Controls
                </a>
              </li>
              <li className="lang-selector-container">
                <input
                  type="checkbox"
                  id="lang-selector-state"
                  name="lang-selector-state"
                />
                <label
                  htmlFor="lang-selector-state"
                  className="lang-selector-state-label"
                  tabIndex={0}
                  role="button"
                  aria-expanded="false"
                >
                  Language
                </label>
                <form
                  name="languageSelectorForm"
                  id="languageSelectorForm"
                  action="/languageSelector"
                  method="POST"
                >
                  <ul className="lang-selector">
                    <li>
                      <button data-locale="in_ID">Bahasa Indonesia</button>
                    </li>
                    <li>
                      <button data-locale="ms_MY">Bahasa Malaysia</button>
                    </li>
                    <li>
                      <button data-locale="cs_CZ">Čeština</button>
                    </li>
                    <li>
                      <button data-locale="da_DK">Dansk</button>
                    </li>
                    <li>
                      <button data-locale="de_DE">Deutsch</button>
                    </li>
                    <li>
                      <span className="current">English</span>
                    </li>
                    <li>
                      <button data-locale="es_ES">Español</button>
                    </li>
                    <li>
                      <button data-locale="zh_TW">正體中文</button>
                    </li>
                    <li>
                      <button data-locale="fr_FR">Français</button>
                    </li>
                    <li>
                      <button data-locale="ko_KR">한국어</button>
                    </li>
                    <li>
                      <button data-locale="it_IT">Italiano</button>
                    </li>
                    <li>
                      <button data-locale="zh_CN">简体中文</button>
                    </li>
                    <li>
                      <button data-locale="nl_NL">Nederlands</button>
                    </li>
                    <li>
                      <button data-locale="ja_JP">日本語</button>
                    </li>
                    <li>
                      <button data-locale="no_NO">Norsk</button>
                    </li>
                    <li>
                      <button data-locale="pl_PL">Polski</button>
                    </li>
                    <li>
                      <button data-locale="pt_BR">Português</button>
                    </li>
                    <li>
                      <button data-locale="ro_RO">Română</button>
                    </li>
                    <li>
                      <button data-locale="ru_RU">Русский</button>
                    </li>
                    <li>
                      <button data-locale="sv_SE">Svenska</button>
                    </li>
                    <li>
                      <button data-locale="tl_PH">Tagalog</button>
                    </li>
                    <li>
                      <button data-locale="th_TH">ภาษาไทย</button>
                    </li>
                    <li>
                      <button data-locale="tr_TR">Türkçe</button>
                    </li>
                    <li>
                      <button data-locale="ar_AE">العربية</button>
                    </li>
                  </ul>
                  <input type="hidden" name="i18nLang" defaultValue />
                  <input type="hidden" name="currenturl" defaultValue />
                </form>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
  
  
}

export default LinkedIn;
